// function toggleIcon(e) {
//     $(e.target)
//         .prev('.panel-heading')
//         .find('.more-less')
//         .toggleClass('fa-plus fa-minus');
// }


$(document).ready(function(){

    // Main navigation menu: add hover effect to main menus (the hover menu in Bootstrap is not implemented by default)
    $('ul.nav li.dropdown').hover(function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn();
        $(this).find('.dropdown-toggle').addClass('open');
    }, function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut();
        $(this).find('.dropdown-toggle').removeClass('open');
    });


    // Category template: add pull-down effect on section type pages for section-items
    var timer, elem_to_show, delay = 500;
    $('.section-item').hover(
        // on mouse in 
        function() {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            elem_to_show = $(this).find('.section-item-info');
            timer = setTimeout( function() {
                elem_to_show.stop( true, true ).slideDown('400'); // slideDown: show he matched elements with a sliding motion
            }, delay);
        }, 
        // on mouse out
        function() {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            elem_to_show.stop( true, true ).slideUp('400'); // slideUp: hide the matched elements with a sliding motion
        }
    );

    // If the user clicks on the section item, simply follow the link contained in the 'Detail' button
    $('.section-item').on('click', function(){
        window.location = $(this).find('a').attr('href');
    });

});
